import React, { useEffect, useState } from "react"
import { styled } from "@mui/material/styles"
import { Box, Button, TableBody, TableCell, TableHead, TableRow, Typography, Table } from "@mui/material"
import Popup from "../../components/popup"
import { CancelAnalysisModal } from "../Dashboard/DashboardComponents/ExperimentSection/CancelAnalysisModal"
import { tableCellClasses } from "@mui/material/TableCell"
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined"

const TableContainer = styled(Box)(({ theme }) => ({
    padding: "0",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: theme.palette["surface-container-high"],
    marginTop: "16px",
    overflow: "hidden"
}))

const NoDataBox = styled(Box)({
    display: "flex",
    flexDirection: "column"
})



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "10px 16px",
    border: "none",
    fontVariantNumeric: "lining-nums tabular-nums slashed-zero",
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette["surface-container-high"],
        color: theme.palette["on-surface"],
        textTransform: "uppercase",
        fontSize: theme.styles.label["small - demi"]?.fontSize,
        fontWeight: theme.styles.label["small - demi"]?.fontWeight,
        lineHeight: theme.styles.label["small - demi"]?.lineHeight,
        letterSpacing: theme.styles.label["small - demi"]?.letterSpacing
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: theme.styles.body.medium.fontSize,
        fontWeight: theme.styles.body.medium.fontWeight,
        lineHeight: theme.styles.body.medium.lineHeight,
        letterSpacing: theme.styles.body.medium.letterSpacing,
        color: theme.palette.primary.main
    }
}))

interface RecentAnalysisTableProps {
    recentAnalyses: any[]
    perPage?: number | undefined
    headers?: any[]
    formatTime: any
}

const AnalysisTable: React.FC<RecentAnalysisTableProps> = ({ recentAnalyses, perPage, headers, formatTime }) => {
    const [isCancelModalOpen, setCancelModalOpen] = useState(false)
    const [filtered, setFiltered] = useState(recentAnalyses)

    console.log(recentAnalyses)
    const statusIcons = {
        processing: {
            icon: (
                <TimerOutlinedIcon sx={{ fontSize: "14px", marginRight: "6px", color: "#F7218C" }} color="secondary" />
            ),
            color: "on-surface"
        },
        canceled: {
            icon: <ErrorOutlineOutlinedIcon sx={{ fontSize: "14px", marginRight: "6px" }} color="error" />,
            color: "error"
        },
        failed: {
            icon: <ErrorOutlineOutlinedIcon sx={{ fontSize: "14px", marginRight: "6px" }} color="error" />,
            color: "error"
        },
        completed: {
            icon: <CheckCircleOutlinedIcon sx={{ fontSize: "14px", marginRight: "6px" }} color="primary" />,
            color: "primary"
        },
        ready: {
            icon: <CheckCircleOutlinedIcon sx={{ fontSize: "14px", marginRight: "6px" }} color="primary" />,
            color: "primary"
        }
    }

    const columns = headers || [
        { field: "experiment_id", headerName: "Experiment ID" },
        { field: "experiment_name", headerName: "Experiment Name" },
        { field: "analysis_id", headerName: "Analysis ID" },
        { field: "time_started", headerName: "Time Started" },
        { field: "status", headerName: "Status" }
    ]

    useEffect(() => {
        setFiltered(recentAnalyses?.slice(0, perPage))
    }, [perPage, recentAnalyses])

    return (
        <TableContainer>
            {filtered?.length ? (
                <>
                    {
                        recentAnalyses?.slice(0, perPage) ? (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Analysis ID</StyledTableCell>
                                        <StyledTableCell sx={{ width: "130px" }}>Analysis Name</StyledTableCell>
                                        <StyledTableCell sx={{ width: "300px" }}>Time Started</StyledTableCell>
                                        <StyledTableCell sx={{ width: "300px" }}>Time Completed</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {recentAnalyses.length > 0 ? (
                                        recentAnalyses.slice(0, perPage).map((row: any, index) => {
                                            return (
                                                <TableRow>
                                                    <StyledTableCell sx={{ width: 230 }}>
                                                        <Typography sx={{ textTransform: "uppercase", fontSize: 14 }}>
                                                            {`AN-${row?.analysis_num}`}
                                                        </Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell sx={{ width: 478 }}>{row?.title}</StyledTableCell>
                                                    <StyledTableCell sx={{ width: 213 }}>
                                                        {formatTime(row?.time_started)}
                                                    </StyledTableCell>
                                                    <StyledTableCell sx={{ width: 213 }}>
                                                        {formatTime(row.time_completed)}
                                                    </StyledTableCell>
                                                    <TableCell
                                                        sx={{
                                                            border: "none",
                                                            alignItems: "center"
                                                        }}>
                                                        <Box display="flex" alignItems="center">
                                                            {
                                                                Object.entries(statusIcons).find(
                                                                    which => which[0] === row.status
                                                                )?.[1].icon
                                                            }
                                                            <Typography
                                                                variant="label"
                                                                size="small - demi"
                                                                color={
                                                                    Object.entries(statusIcons).find(
                                                                        which => which[0] === row.status
                                                                    )?.[1].color
                                                                }>
                                                                {row.status}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell sx={{ border: "none" }}>
                                                        {row.status === "completed" && (
                                                            <Button
                                                                onClick={() =>
                                                                    (window.location.href = `${window.location.origin}/visualizer/?analysis_id=${row.analysis_id}&exp=${row.experiment_id}`)
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    padding: "4px 8px",
                                                                    borderRadius: "12px",
                                                                    backgroundColor: "#000",
                                                                    fontSize: "12px",
                                                                    height: "unset",
                                                                    fontFamily: "Maison Neue",
                                                                    color: "#fff",
                                                                    lineHeight: "16px",
                                                                    letterSpacing: "0.5px",
                                                                    textTransform: "uppercase",
                                                                    "&:hover": { backgroundColor: "#000d" }
                                                                }}>
                                                                Visualize
                                                            </Button>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={6}
                                                sx={{
                                                    backgroundColor: "surface-container-low",
                                                    width: "794px",
                                                    height: "176px",
                                                    border: "none"
                                                }}>
                                                <Typography variant="body" size="medium" align="center">
                                                    No Analyses to display.
                                                </Typography>
                                                {/* <Box
                                                width="794px"
                                                height="176px"
                                                bgcolor="surface-container-low"
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center">
                                                <Typography variant="body" size="medium">
                                                    No analyses have been processed lately.
                                                </Typography>
                                            </Box> */}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        ) : null
                        // <NoDataBox sx={{ border: ".5px solid", borderColor: "outline-variant" }}>
                        //     <Box display="flex" justifyContent="space-between">
                        //         {columns.map(c => (
                        //             <Box
                        //                 bgcolor="inverse-on-surface"
                        //                 border=".5px solid"
                        //                 borderColor="outline-variant"
                        //                 width="100%"
                        //                 padding=".25% 1%">
                        //                 {c.headerName}
                        //             </Box>
                        //         ))}
                        //     </Box>
                        //     <Box margin="0 auto" padding="7% 0">
                        //         No analyses have been processed lately.
                        //     </Box>
                        // </NoDataBox>
                    }
                </>
            ) : (
                <NoDataBox sx={{ borderColor: "outline" }}>
                    <Typography variant="body" size="large" sx={{ margin: "0 auto", padding: "3% 0" }}>
                    No Analyses to display.
                    </Typography>
                </NoDataBox>
            )}

            <Popup
                isOpen={isCancelModalOpen}
                onClose={() => setCancelModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <CancelAnalysisModal />
            </Popup>
        </TableContainer>
    )
}

export default AnalysisTable
